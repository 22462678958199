import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { EmailOutlined } from "@material-ui/icons"

const EmailSentMessage = ({ message, resendMessage }) => {
  return (
    <Grid container justify="center">
      <EmailOutlined color="secondary" style={{ paddingTop: "24px" }} />
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          {message ||
            `En caso de que tu correo esté registrado te envíaremos un correo para
          recuperar tu contraseña.`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EmailSentMessage
