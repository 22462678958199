import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Grid,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { Button } from "instadrops-ui"
import { navigate } from "gatsby"
import { callAnalyticsEvent } from "../utils"
import "./styles.css"
import useAuth from "../context/auth"
import { BACKEND_AUTH_ROUTES } from "../utils/consts"
import axios from "axios"
import Logo from "./Logo"
import { LOGIN_ERROR_MESSAGES } from "../utils/consts"
import EmailSentMessage from "./EmailSentMessage"
import { isEmail } from "validator" // Import isEmail from validator

const RecoverPasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const [errorCode, setErrorCode] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const { isAuthenticated } = useAuth()

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  })

  const formState = watch()

  const isButtonDisabled = !formState.email.length

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  })

  const onSubmit = () => {
    setLoading(true)

    const { email } = formState

    axios
      .post(
        BACKEND_AUTH_ROUTES.resetPassword,
        {
          email: email.trim(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const { success } = response.data

        if (success) {
          setLoading(false)
          setEmailSent(true)
          callAnalyticsEvent("request_reset_password.success")
        }
      })
      .catch((err) => {
        callAnalyticsEvent("request_reset_password.error")
        if (err?.response?.status === 401) {
          setErrorCode(401)
        } else {
          setErrorCode(0)
        }
        setOpenSnackbar(true)

        setLoading(false)
      })
  }

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      style={{ height: "90vh" }}
    >
      <Grid item xs={8} container justify="center">
        <Typography variant="h3">Alchtec.com</Typography>
      </Grid>
      {loading ? (
        <Grid
          style={{ height: "30vh" }}
          justify="center"
          alignContent="center"
          container
        >
          <CircularProgress />
        </Grid>
      ) : emailSent ? (
        <EmailSentMessage />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justify="center">
            <Grid item xs={11} md={8}>
              <Grid container>
                <Grid item xs={12} style={{ padding: 12, textAlign: "center" }}>
                  <Typography>
                    Para recuperar la contraseña, ingresa tu email de registro.
                  </Typography>
                </Grid>
                <Grid item style={{ padding: 12 }} xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email is required",
                      validate: (value) =>
                        isEmail(value) || "Please enter a valid email address",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Email"
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Button
                    disabled={isButtonDisabled}
                    isSubmit
                    size="small"
                    color="gradient"
                  >
                    Ingresar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {LOGIN_ERROR_MESSAGES[errorCode]}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default RecoverPasswordForm
