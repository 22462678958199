import React from "react"
import AuthPage from "../components/AuthPage"
import { BasicLayout } from "../layouts/index.js"
import RequestRecoverPassword from "../components/RequestRecoverPassword"

const RecoverPasswordPage = () => {
  return (
    <BasicLayout>
      <AuthPage content={RequestRecoverPassword} />
    </BasicLayout>
  )
}

export default RecoverPasswordPage
